import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import CampaignCard from "../../components/campaign-card";
import Hero from "../../components/hero";
import NewsletterBanner from "../../components/newsletter-banner";

const OurCampaignsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Our-Campaigns-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpCampaign(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					dateGmt
					title
					slug
					campaignFields {
						introductionParagraph
						featuredImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Our Campaigns",
				item: {
					url: `${siteUrl}/about-us/our-campaigns`,
					id: `${siteUrl}/about-us/our-campaigns`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Our Campaigns | The Ella Roberta Foundation"
				description="Follow our blog to get the latest information on what the Foundation is doing. We post stories here as they happen, whether it's grassroots campaigning, or government level lobbying for clean air."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/our-campaigns`,
					title: "Our Campaigns | The Ella Roberta Foundation",
					description:
						"Follow our blog to get the latest information on what the Foundation is doing. We post stories here as they happen, whether it's grassroots campaigning, or government level lobbying for clean air.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bg-white">
					<Hero
						title={
							<span>
								OUR
								<br />
								CAMPAIGNS
							</span>
						}
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
					{/* <section className="py-5 py-lg-8">
						<Container>
							<Row className="justify-content-center text-center">
								<Col>
									<h2 className="fs-1 text-primary pb-4">EVENTS & NEWS</h2>
									<p>
										Follow our blog to get the latest information on what the
										Foundation is doing. We post stories here as they happen,
										whether it's grassroots campaigning, or government level
										lobbying for clean air.
									</p>
									<Button
										className="fs-5 w-100 w-md-auto px-5 mt-4 py-2"
										variant="outline-black"
										to="/rss.xml"
										as={Link}
									>
										SIGN UP
									</Button>
								</Col>
							</Row>
						</Container>
					</section> */}
					<section className="py-5">
						<Container>
							{data.allWpCampaign.nodes
								.filter((item, index) => index === 0)
								.map((campaign) => (
									<Row className="mb-5 align-items-center">
										<Col lg={6}>
											<h2 className="mb-4">{campaign.title}</h2>
											<p>{campaign.campaignFields.introductionParagraph}</p>
											<Button
												className="fs-5 w-100 w-md-auto px-4 mt-4 py-2"
												variant="outline-black"
												to={`/campaigns/${campaign.slug}`}
												as={Link}
											>
												READ MORE
											</Button>
										</Col>
										<Col
											className="mb-5 mb-lg-0"
											xs={{ span: 12, order: "first" }}
											lg={{ span: 6, order: "last" }}
										>
											<GatsbyImage
												className="w-100 "
												image={
													campaign.campaignFields.featuredImage.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={campaign.campaignFields.featuredImage.altText}
											/>
										</Col>
									</Row>
								))}
						</Container>
					</section>
					<section>
						<Container>
							<Row>
								{data.allWpCampaign.nodes
									.filter((item, index) => index > 0)
									.map((campaign) => (
										<Col className="mb-5" lg={6} xl={4}>
											<CampaignCard
												image={
													campaign.campaignFields.featuredImage.localFile
														.childImageSharp.gatsbyImageData
												}
												imageAlt={campaign.campaignFields.featuredImage.altText}
												title={campaign.title}
												description={
													campaign.campaignFields.introductionParagraph
												}
												uri={`/campaigns/${campaign.slug}`}
											/>
										</Col>
									))}
							</Row>
						</Container>
					</section>

					{/* <NewsletterBanner /> */}
				</div>
			</Layout>
		</>
	);
};

export default OurCampaignsPage;
