import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import NewsletterBanner from "../../components/newsletter-banner";

const OurWorkPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Our-Work-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Our Work",
				item: {
					url: `${siteUrl}/about-us/our-work`,
					id: `${siteUrl}/about-us/our-work`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Our Work | The Ella Roberta Foundation"
				description="At the Ella Roberta Foundation we believe in a world where clean air is a human right. We think everyone should be able to breathe air that is free from toxic pollution, regardless of where they live, their economic status or their ethnic background."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/our-work`,
					title: "Our Work | The Ella Roberta Foundation",
					description:
						"At the Ella Roberta Foundation we believe in a world where clean air is a human right. We think everyone should be able to breathe air that is free from toxic pollution, regardless of where they live, their economic status or their ethnic background.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<div className="bg-background">
					<Hero
						title={
							<span>
								OUR
								<br />
								WORK
							</span>
						}
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
					<section className="py-5 py-lg-8">
						<Container>
							<Row className="justify-content-center">
								<Col className="pb-5 pb-ld-0" lg={6}>
									<h2 className="fs-1 text-primary pb-4">VISION</h2>
									<p>
										At the Ella Roberta Foundation we believe in a world where
										clean air is a human right. We think everyone should be able
										to breathe air that is free from toxic pollution, regardless
										of where they live, their economic status or their ethnic
										background.
									</p>
								</Col>
								<Col lg={6}>
									<h2 className="fs-1 text-primary pb-4">MISSION</h2>
									<p>
										We believe the{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.judiciary.uk/wp-content/uploads/2021/04/Ella-Kissi-Debrah-2021-0113-1.pdf"
										>
											Coroner’s recommendations
										</a>{" "}
										issued after the landmark inquest into Ella’s death have the
										power to improve air quality for everyone, everywhere. Our
										work is to campaign for these guidelines to be followed by
										governments, councils, medical professionals and the general
										public, all over the world.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="bg-primary py-5 pt-lg-8 text-center ">
						<Container>
							<Row>
								<Col>
									<h2 className="display-3 d-none d-md-block gobold-reg text-white pb-5">
										CORONER’S
										<br /> RECOMMENDATIONS
									</h2>
									<h2 className="fs-1 gobold-reg d-md-none text-white pb-5">
										CORONER’S
										<br /> RECOMMENDATIONS
									</h2>
									<p className="text-white text-start text-lg-center">
										1. National air pollution limits should be in line with WHO
										guidelines.
										<br /> 2. Raise awareness of the dangers of air pollution
										amongst the general public.
										<br /> 3. Ensure health professionals are aware of the
										dangers of air pollution, and that they tell their patients
										about it.
									</p>
									<Button
										className="fs-5 w-100 w-md-auto px-5 mt-4 py-2"
										variant="outline-white"
										href="https://ella-roberta-foundation.rjmdigital.net/wp-content/uploads/2022/06/Ella-Kissi-Debrah-2021-Preventionoffuturedeathsreport.pdf"
										target="_blank"
										rel="noreferrer"
									>
										THE REPORT
									</Button>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="py-5 py-lg-8">
						<Container>
							<Row className="mb-5">
								<Col>
									<h2 className="display-3 gobold-reg pb-5 heading-text">
										REDUCE AIR
										<br /> POLLUTION
									</h2>
									<p>
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.gov.uk/government/news/public-health-england-publishes-air-pollution-evidence-review"
										>
											Air pollution
										</a>{" "}
										kills 40,000 people in the UK every year. It reduces
										children’s lung growth and function, affecting their health
										throughout their lives and causing respiratory diseases such
										as asthma. In adults it is linked to strokes, heart disease,
										diabetes as well as neurodegenerative conditions.
									</p>
									<p>
										The{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.theguardian.com/environment/2018/oct/27/air-pollution-is-the-new-tobacco-warns-who-head#:~:text=Air%20pollution%20is%20the%20%E2%80%9Cnew,year%20and%20harming%20billions%20more"
										>
											WHO calls air pollution the ‘new tobacco’
										</a>{" "}
										as it causes a similar level of disease to smoking. They say
										there are ‘no safe limits’ of PM2.5 air pollution, and the
										coroner at Ella’s inquest agreed. In his Prevention of
										Future Deaths report, he put forward that UK air pollution
										limits should be the same as those issued by the WHO in
										order to reduce the number of deaths from air pollution.
										Currently the UK is bound by EU air pollution limits (until
										new post-Brexit guidelines are issued by the government),
										but the{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.who.int/news/item/22-09-2021-new-who-global-air-quality-guidelines-aim-to-save-millions-of-lives-from-air-pollution"
										>
											WHO
										</a>{" "}
										2021 guidelines are significantly lower and following them
										could avoid 80% of deaths related to PM2.5 worldwide.
									</p>
								</Col>
							</Row>
						</Container>

						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="justify-content-end mx-lg-0 px-lg-0 ">
								<Col lg={8} className="mx-lg-0 px-lg-0  text-white">
									<div
										className="bg-black w-100 h-100 p-md-5 p-4  ps-md-6"
										style={{ boxShadow: "0px 3px 70px #707070" }}
									>
										<p className="fs-3">
											We are campaigning for the UK government to reduce air
											pollution targets to those recommended in the{" "}
											<a
												className="white-link text-decoration-underline"
												target="_blank"
												rel="noreferrer"
												href="https://www.who.int/news/item/22-09-2021-new-who-global-air-quality-guidelines-aim-to-save-millions-of-lives-from-air-pollution"
											>
												WHO 2021 guidelines
											</a>
											.
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="py-5 bg-background-dark ">
						<Container>
							<Row className="mb-5 text-white">
								<Col>
									<h2 className="display-3 gobold-reg pb-5 heading-text">
										RAISE
										<br />
										AWARENESS
									</h2>
									<p>
										The report found that most people don’t know about the
										impact air pollution has on their and their children’s
										health or where to get information on air pollution levels.
										Because of this they’re not able to reduce the amount they
										are exposed to, for example, by avoiding busy roads. If
										Ella’s mother, Rosamund, had known about air pollution and
										the huge effect it can have on children, she could have
										changed elements of their lives to reduce Ella’s exposure to
										it.
									</p>
								</Col>
							</Row>
						</Container>

						<Container fluid className="px-lg-0  mx-lg-0">
							<Row className="justify-content-start mx-lg-0 px-lg-0 mb-7">
								<Col lg={8} className="mx-lg-0 px-lg-0  ">
									<div
										className="bg-white w-100 h-100 p-md-5 p-4"
										style={{ boxShadow: "0px 3px 99px #000000C4" }}
									>
										<p className="fs-3">
											We believe that by campaigning to raise awareness amongst
											the general public, we are enabling people to make
											informed decisions so they can lower their exposure to air
											pollution. These decisions could range from walking
											through a park rather than on a busy road, to lobbying
											their MP and the government.
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 pb-lg-8">
						<Container>
							<Row className="mb-5">
								<Col>
									<h2 className="display-3 gobold-reg pb-5 heading-text">
										EDUCATE HEALTH
										<br />
										PROFESSIONALS
									</h2>
									<p>
										The Coroner was concerned that the dangers of air pollution
										were not being sufficiently communicated to patients and
										their carers by the medical profession. He recommended that
										this should be addressed through undergraduate and
										postgraduate training, as well as the professional medical
										bodies. In addition to this, the Foundation is committed to
										promoting research into the links between air pollution and
										asthma, so that the medical profession and members of the
										public can better understand the health risks and how they
										can be treated.
									</p>
								</Col>
							</Row>
						</Container>

						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="justify-content-end mx-lg-0 px-lg-0 ">
								<Col lg={8} className="mx-lg-0 px-lg-0  text-white">
									<div
										className="bg-primary w-100 h-100 p-md-5 p-4 ps-md-6"
										style={{ boxShadow: "0px 3px 70px #707070" }}
									>
										<p className="fs-3">
											We believe that by working with medical professionals,
											whether that’s promoting research or increasing awareness
											amongst health workers, we can help everyone make the link
											between health and air pollution.
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>

					{/* <NewsletterBanner /> */}
				</div>
			</Layout>
		</>
	);
};

export default OurWorkPage;
