import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import CampaignCard from "../components/campaign-card";
import Hero from "../components/hero";
import NewsletterBanner from "../components/newsletter-banner";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";

const CampaignsTemplate = ({
	data: { campaign, campaigns, site, catCampaigns },
}) => {
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Campaigns",
				item: {
					url: `${site.siteMetadata.siteUrl}/campaigns`,
					id: `${site.siteMetadata.siteUrl}/campaigns`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${campaign.title}`,
				item: {
					url: `${site.siteMetadata.siteUrl}/campaigns/${campaign.slug}`,
					id: `${site.siteMetadata.siteUrl}/campaigns/${campaign.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${campaign.title} | The Ella Roberta Foundation`}
				description={campaign.campaignFields.introductionParagraph}
				language="en"
				openGraph={{
					title: `${campaign.title} | The Ella Roberta Foundation`,
					description: `${campaign.campaignFields.introductionParagraph}`,
					url: `${site.siteMetadata.siteUrl}/campaigns/${campaign.slug}`,
					type: "article",
					images: [
						{
							url: `${campaign.campaignFields.featuredImage.localFile.publicURL}`,
							width: `${campaign.campaignFields.featuredImage.localFile.childImageSharp.original.width}`,
							height: `${campaign.campaignFields.featuredImage.localFile.childImageSharp.original.height}`,
							alt: `${campaign.campaignFields.featuredImage.altText}`,
						},
					],
				}}
			/>
			<section>
				<div className="bg-primary pt-5 pt-lg-7 pb-10">
					{" "}
					<h1 className="text-white big-text text-center mb-2  gobold-reg ">
						{campaign.title}
					</h1>
				</div>
				<Container>
					<Row className="justify-content-center">
						<Col lg={10} xl={8}>
							<div
								style={{
									top: "-6rem",
								}}
								className="position-relative"
							>
								<GatsbyImage
									style={{ minHeight: "30rem" }}
									className=" w-100 "
									image={
										campaign.campaignFields.featuredImage.localFile
											.childImageSharp.gatsbyImageData
									}
									alt={campaign.campaignFields.featuredImage?.altText}
								/>
								<p style={{ fontSize: "80%" }} className="text-center">
									{campaign.campaignFields.featuredImage?.altText}
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section>
				<Container>
					<Row className="justify-content-center">
						<Col
							className="position-relative"
							style={{
								top: "-4rem",
							}}
							lg={10}
							xl={8}
						>
							{parse(campaign.content)}
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<Row>
						<Col className="text-center text-primary  mt-lg-8 mt-5">
							<h2 className="pb-5 display-3 heading-text">
								FIND OUT MORE ABOUT
								<br className="d-none d-md-block" /> OUR CAMPAIGNS
							</h2>
						</Col>
					</Row>
					<Row>
						{catCampaigns.nodes.length === 0 &&
							campaigns.nodes
								.filter((item, index) => index < 3)
								.map((campaign) => (
									<Col className="mb-5" lg={6} xl={4}>
										<CampaignCard
											image={
												campaign.campaignFields.featuredImage.localFile
													.childImageSharp.gatsbyImageData
											}
											imageAlt={campaign.campaignFields.featuredImage.altText}
											title={campaign.title}
											description={
												campaign.campaignFields.introductionParagraph
											}
											uri={`/campaigns/${campaign.slug}`}
										/>
									</Col>
								))}
						{catCampaigns.nodes.length > 0 &&
							catCampaigns.nodes
								.filter((item, index) => index < 3)
								.map((campaign) => (
									<Col className="mb-5" lg={6} xl={4}>
										<CampaignCard
											image={
												campaign.campaignFields.featuredImage.localFile
													.childImageSharp.gatsbyImageData
											}
											imageAlt={campaign.campaignFields.featuredImage.altText}
											title={campaign.title}
											description={
												campaign.campaignFields.introductionParagraph
											}
											uri={`/campaigns/${campaign.slug}`}
										/>
									</Col>
								))}
					</Row>
				</Container>
			</section>
			{/* <NewsletterBanner /> */}
		</Layout>
	);
};

export default CampaignsTemplate;

export const pageQuery = graphql`
	query CampaignPostById($id: String!, $cat: String!) {
		campaign: wpCampaign(id: { eq: $id }) {
			dateGmt
			title
			content
			slug
			campaignFields {
				category
				introductionParagraph
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}
		campaigns: allWpCampaign(
			sort: { order: DESC, fields: dateGmt }
			filter: { id: { ne: $id } }
		) {
			nodes {
				dateGmt
				title
				slug
				campaignFields {
					introductionParagraph
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
		catCampaigns: allWpCampaign(
			sort: { order: DESC, fields: dateGmt }
			filter: { id: { ne: $id }, campaignFields: { category: { eq: $cat } } }
		) {
			nodes {
				dateGmt
				title
				slug
				campaignFields {
					category
					introductionParagraph
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
